import { IonBackButton, IonButton, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import '../pages/Login.css'
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { chevronBack, chevronForward } from 'ionicons/icons'
import { withRouter } from 'react-router';
import { useEffect, useState } from "react";
import axios from "axios";
import { api, open, url } from "../store";
import Select from 'react-select'
import { Storage } from "@capacitor/storage";
const OrderCreate = (props) => {
  const [code,setCode]=useState('')
  const [mobile,setMobile]=useState('')
  const [address,setAddress]=useState('')
  const [mobile1,setMobile1]=useState('')
  const [note,setNote]=useState('')
  const [amount,setAmount]=useState('')
  const [person,setPerson]=useState('')
  const [area_id,setArea]=useState('')
  const [areas,setAreas]=useState([])
  const [areasList,setAreasList]=useState([])
  const [delivery,setDelivery]=useState(0)
  const [present, dismiss] = useIonToast();
  useEffect(()=>{
    axios.get(url+'api/areas').then(res=>{
      setAreasList(res.data)
      var list=[]
      res.data.map((item)=>{
        list.push({label:item.price+' '+item.name,value:item.id})
      })
      setAreas(list)
    }).catch(e=>console.log(e))
  },[])

  const save=async()=>{
      var token=await localStorage.getItem('token')
    open.next(true)
    axios.post(api+'orders',{
      code:code,amount:amount,mobile:mobile,note:note,address:address,mobile1:mobile1,
      delivery:delivery,is_pending:1,
     area_id:area_id,token:token
    })
    .then(async(res)=>{
      open.next(false)
      console.log(res.data)
        
        props.history.goBack()
      

    }).catch(e=>{console.log(e)
      open.next(false)})
  }
    return (
      <IonPage>
          <IonHeader>
              <IonToolbar>
                  <IonBackButton slot="end"></IonBackButton>
                  <IonTitle>إضافة طلب</IonTitle>
              </IonToolbar>
          </IonHeader>
        <IonContent  className="content">
           
            <img src="login.webp" className="cover-img" style={{position:"absolute",zIndex:"-10"}} />
        <div className="my-auto mt-40 rounded-lg  mx-auto p-4" style={{maxWidth:"350px",background:"#fff"}}>
        <TextField fullWidth  className="w-100" label="رقم الطلب"
        value={code}
        onChange={(e)=>setCode(e.target.value)}
         variant="standard" />
        <TextField fullWidth type="number"  className="w-100" label="قيمة الطلب"
         value={amount}
         onChange={(e)=>setAmount(e.target.value)}
         variant="standard" />

        <TextField fullWidth  className="w-100" 
         value={mobile} type="number"
         onChange={(e)=>setMobile(e.target.value)}
        label="رقم الهاتف" variant="standard" />
        <TextField fullWidth  className="w-100" 
         value={mobile1}
         onChange={(e)=>setMobile1(e.target.value)}
        label="رقم هاتف اخر " variant="standard" />
        <TextField fullWidth 
         value={address}
         onChange={(e)=>setAddress(e.target.value)}
         className="w-100" label="العنوان" variant="standard" />
         <label>المنطقة</label>
        <Select placeholder="المنطقة" options={areas} onChange={(e)=>{setArea(e.value)
        setDelivery(areasList.filter(item=>item.id==e.value)[0].price)
        }} />
        <br />

        <TextField fullWidth 
         value={note}
         onChange={(e)=>setNote(e.target.value)}
         className="my-6" label="ملاحظة" variant="standard" />
      
        
        <br />
        <table className="table w-100" style={{width:'100%'}}>
          <tbody>          <tr>
            <th>التوصيل</th>
            <td>{delivery}</td>
          </tr>
          </tbody>

        </table>
       
        
        
        <Button variant="contained" color="success"
        onClick={()=>save()}
        disabled={!code||!mobile||amount<1||!address||!area_id}
         style={{marginTop:"1rem",width:"100%",borderRadius:".5rem"}} >حفظ</Button>
      
        </div>
        </IonContent>
      </IonPage>
    );
  };
  
  export default withRouter(OrderCreate);