import { Storage } from "@capacitor/storage"
import { IonBadge, IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonSearchbar } from "@ionic/react"
import { TextField } from "@mui/material"
import { data } from "autoprefixer"
import axios from "axios"
import { add, bicycle, call, cash, location } from "ionicons/icons"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, myorder } from "../store"
import moment from 'moment'
import { withRouter } from 'react-router';
 function Orders (props){
    const [orders,setOrders]=useState([])
    const [mydate,setDate]=useState('')
    const [input,setInput]=useState('')

    useEffect(()=>{
        getData('')
    },[])

   const getData=async(d)=>
    {
        setDate(d)
        var token=await localStorage.getItem('token')
        axios.get(api+'orders/'+token+"?date="+d).then(res=>{
            console.log(res.data)
            if(res.data.result)
            {
          //  setDate(res.data.date)
            setOrders(res.data.orders)
            }
        })
        .catch(e=>console.log(e))
    }
    return (
        <div className="p-2">
            <IonFab vertical="bottom" horizontal="start" slot="fixed">
                <Link to="/order/create">
                <IonFabButton>
                <IonIcon icon={add}></IonIcon>
                </IonFabButton>
                </Link>
            </IonFab>

            <TextField type='date' 
             InputLabelProps={{
                shrink: true,
              }}
            label="التاريخ" fullWidth  variant="standard" value={mydate} 
            onChange={async(e)=>{
              
               getData(e.target.value) 
              
         
         //    getData()

             }} />

            <IonSearchbar mode="ios" placeholder="بحث "
             onIonChange={(e) => setInput(e.detail.value)}
             />
            {orders.filter(item=>item.mobile.toString().indexOf(input)>-1||item.code?.toString().indexOf(input)>-1||item.amount.toString().indexOf(input)>-1
            )
            .map((item,key)=>{
                return <IonItem  key={key} onClick={()=>{
                    myorder.next(item)
                    props.history.push('/order/update')
                }} >
                    <button size="small" slot="start" style={{width:"35px",height:"35px",borderRadius:"35px",marginLeft:"5px",color:item.status.color,background:item.status.bgcolor}}>
                      {item.id}

                    </button>
                    <IonLabel>
                    <p>{moment(item.created_at).format('YYYY-MM-DD HH:mm:s')}</p>
                    <span > ({item.code}) </span>
                    <span  

                    style={{padding:"5px 8px",borderRadius:'5px',color:item.status?.color,background:item.status?.bgcolor}}>
                        {item.status?.name}
                    </span>
                    <br />
                    <span >
                   <IonIcon  icon={call} className="me-1"></IonIcon>  {item.mobile}
                   </span>
                     <br />
                   <IonIcon  icon={location}></IonIcon>
                   <span>  {item.area?.name} </span> {item.address} 
                    

                   
                    </IonLabel>
                    <div slot="end">
                        
                        <IonBadge color="danger" >
                            <IonIcon className="mx-1 " style={{fontSize:"14px"}} icon={bicycle}></IonIcon>
                        {new Intl.NumberFormat('en-US').format(item.delivery)}
                    </IonBadge> <br/>
                    <IonBadge >
                    <IonIcon className="mx-1" icon={cash}></IonIcon>
                        { new Intl.NumberFormat('en-US').format(item.amount)}
                    </IonBadge>
                    </div>
                    
                </IonItem>
            })}

        </div>
    )
}

export default withRouter(Orders)