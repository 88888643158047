
import React, { useEffect } from 'react';
import { Storage } from '@capacitor/storage';
import { IonContent, IonPage } from '@ionic/react';
import { IonNav } from '@ionic/react';
import { withRouter } from 'react-router';
const    Load=(props)=> {
  useEffect(()=>{
    getToken()
  },[])

  const  getToken=async ()=>{
    var token=localStorage.getItem('token')
    console.log(token)
    if(token)
    {
        
        props.history.replace('/home');
    }
    else
    {
        props.history.replace("/login")
    }
  }


        return (
            <IonPage>
      
        <IonContent fullscreen className="content"></IonContent>
        </IonPage>
        )
        
    
}

export default withRouter(Load);
