import { IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { barChart, homeOutline, locate } from 'ionicons/icons';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ThreeDRotation from '@mui/icons-material/ThreeDRotation';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ExploreContainer from '../components/ExploreContainer';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import './Home.css';
import React, { useEffect, useState } from 'react';
import Orders from '../components/Orders';
import axios from 'axios';
import { api, mycustomer } from '../store';
import { Storage } from '@capacitor/storage';
import { withRouter } from 'react-router';
import Customer from '../components/Customer';
import reactSelect from 'react-select';
import Dash from '../components/Dash';




class Home extends React.Component {
constructor(props)
{
  super(props)
  this.state={customer:null,tab:0}
  
}


componentDidMount()
{
  this.getCustomer()
}
 

  async getCustomer(){
    var token=await localStorage.getItem('token')
  //  alert(token)
    setTimeout(()=>{
    axios.get(api+'auth/profile/'+token).then(async (res)=>{
      console.log(res.data)
      if(res.data.customer)
      {

        mycustomer.next(res.data.customer)
        this.setState({customer:res.data})
      }
      else 
      {
       localStorage.removeItem('token')
       this.props.history.replace("/login")

      }
      console.log(res.data)
    }).catch(e=>{console.log(e)
      
    })
  },2000)
  }

  view()
  {
    switch(this.state.tab) {
      case 0:
      return  <Orders  />
        // code block
        break;
      case 1:
        return  <Dash customer={this.state.customer}  />
        // code block
        break;

        case 2:
          return  <Customer  />
          // code block
          break;

      default:
        // code block
    } 
  }
  render() {
    return (
      <IonPage>
        
        <IonContent >
         {this.view()}
         
        </IonContent>
        <IonFooter>
        <BottomNavigation
    showLabels
    value={this.state.tab}
    onChange={(event, newValue) => {
      this.setState({tab:newValue})
     
    }}
  >
  <BottomNavigationAction  icon={<TwoWheelerIcon />}  onClick={()=>this.setState({tab:0})} />
    <BottomNavigationAction  icon={<BarChartIcon />} onClick={()=>this.setState({tab:1})} />
    <BottomNavigationAction  icon={<PersonIcon />}  onClick={()=>this.setState({tab:2})} />
   
  </BottomNavigation>
        </IonFooter>
      </IonPage>
    );
  }
 
};

export default withRouter(Home);
