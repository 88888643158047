import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import Register from './pages/Register';
import { Backdrop, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { open } from './store';
import OrderCreate from './components/OrderCreate';
import { Storage } from '@capacitor/storage';
import OrderUpdate from './components/OrderUpdate';
import Home from './pages/Home';
import Load from './pages/Load';


setupIonicReact();


const  App=(props)=> { 
  
  const [loader,setLoader]=useState(false)
  useEffect(()=>{
    open.subscribe(res=>setLoader(res))
  },[])

  return  (




  <IonApp className='app'>
    <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={loader}
  onClick={()=>{}}
>
  <CircularProgress color="inherit" />
</Backdrop>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/order/create">
          <OrderCreate />
        </Route>
        <Route exact path="/order/update">
          <OrderUpdate />
        </Route>

        <Route exact path="/load">
          <Load />
        </Route>

        <Route  path="/login">
          <Login />
        </Route>
        <Route  path="/register">
          <Register />
        </Route>
        <Route exact path="/">
          <Redirect to="/load" />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);
}

export default App;
