import { IonInput, IonItem, IonLabel, IonPage,IonContent, IonFabButton, IonFab, IonIcon } from "@ionic/react";
import { TextField } from "@mui/material";
import axios from "axios";
import { save } from "ionicons/icons";
import { Component } from "react";
import { api,mycustomer } from "../store";




export default class Dash extends Component{
    constructor(props)
    {
        super(props)
        this.states=[' 	راجع','تم التنفيذ','مؤجل','','','جاري التنفيذ']
        this.state={start:"",end:"",customer:null,
        count:0,total:0,delivery:0,cost:0,statuses:[],orders:[],customer:this.props.customer.customer}
        
       
    
    }
componentDidMount()
{
    this.getData()
}



    

    getData()
    {
        console.log(api+'orders/report/'+this.state.customer.id+'?start='+this.state.start+'&end='+this.state.end)
        axios.get(api+'orders/report/'+this.state.customer.id+'?start='+this.state.start+'&end='+this.state.end)
        .then(res=>{
            console.log(res)
            this.setState({
                start:res.data.start,
                end:res.data.end,
                count:res.data.order.count,
                amount:res.data.order.amount,
                delivery:res.data.order.delivery,
                cost:res.data.order.cost,
                orders:res.data.orders,
                statuses:res.data.statuses

            })
        })
    }

    download()
    {
        window.open('https://backend.jai3leak.sd/customer/report/'+this.state.customer.id+"?start="+this.state.start+"&end="+this.state.end)
    }

    render()
    {
        return (<>
         <IonPage>
         
        <IonContent  className="p-2">
            <div className="p-2">
        <IonFab vertical="bottom" horizontal="start" slot="fixed" >
                
                <IonFabButton onClick={()=>this.download()}>
                <IonIcon icon={save}></IonIcon>
                </IonFabButton>
                
            </IonFab>
            <div className="flex">
         <TextField type="date" style={{width:"100%"}}
         value={this.state.start}
         onChange={(e)=>{
             this.state.start=e.target.value
             this.setState({start: this.state.start})
             
                this.getData()
             
         }}
          InputLabelProps={{
            shrink: true,
          }} variant="standard" label="من" />
         <TextField type="date" style={{width:"100%"}}
          value={this.state.end}
          onChange={(e)=>{

           this.state.end=e.target.value
             this.setState({start: this.state.end})
            this.getData()
        }}
          InputLabelProps={{
            shrink: true,
          }} variant="standard" label="الى" />
         

         </div>
         <h3>احصائيات الطلبات</h3>
         <table className="table">
             <tbody>
                 <tr>
                     <td>عدد الطلبات</td>
                     <td>{this.state.count}</td>
                 </tr>
                 <tr>
                     <td>قيمة الطلبات الطلبات</td>
                     <td>{this.state.amount}</td>
                 </tr>
                 <tr>
                     <td>التوصيل </td>
                     <td>{this.state.delivery}</td>
                 </tr>
                 <tr>
                     <td>المطالبة </td>
                     <td>{this.state.cost}</td>
                 </tr>
                 
                              </tbody>
         </table>

         <h3> الحالات</h3>
         <div class="table-responsive">
         <table className="table">
            <thead>
                <tr>
                    <th>الحالة</th>
                    <th>عدد</th>
                    <th>اجمالي</th>
                    <th>توصيل</th>
                </tr>
            </thead>
            <tbody>
                {this.state.statuses.map((item,key)=>{
                    return <tr key={key}>
                        <td>{item.name}</td>
                        <td>{item.count}</td>
                        <td>{item.amount}</td>
                        <td>{item.delivery}</td>
                    </tr>
                })}
            </tbody>
         </table>
         
         </div>

         <h3> الطلبات</h3>
         <div class="table-responsive">
         <table className="table">
            <thead>
                <tr>
                    <th>التاريخ</th>
                    <th>عدد</th>
                    <th>اجمالي</th>
                    <th>توصيل</th>
                </tr>
            </thead>
            <tbody>
                {this.state.orders.map((item,key)=>{
                    return <tr key={key}>
                        <td>{item.date}</td>
                        <td>{item.count}</td>
                        <td>{item.amount}</td>
                        <td>{item.delivery}</td>
                    </tr>
                })}
            </tbody>
         </table>
         
         </div>

         
         </div>


          </IonContent>
          </IonPage>
          </>)
    }
}