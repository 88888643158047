import { IonButton, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import '../pages/Login.css'
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { chevronBack, chevronForward } from 'ionicons/icons'
import { withRouter } from 'react-router';
import { useEffect, useState } from "react";
import axios from "axios";
import { api, mycustomer, open, url } from "../store";
import Select from 'react-select'
import { Storage } from "@capacitor/storage";
const Customer = (props) => {
  const [customer,setCustomer]=useState(null)
  const [areas,setAreas]=useState([])
  const [present, dismiss] = useIonToast();
  useEffect(()=>{
      mycustomer.subscribe(res=>setCustomer(res))
    axios.get(url+'api/areas').then(res=>{
      var list=[]
      res.data.map((item)=>{
        list.push({label:item.name,value:item.id})
      })
      setAreas(list)
    }).catch(e=>console.log(e))
  },[])

 const logout=()=>
  {
    localStorage.removeItem('token')
    window.location.replace('/login')
  }

  const save=async ()=>{
      var token=localStorage.getItem('token')
    open.next(true)
    axios.post(api+'auth/update/'+token,customer)
    .then(async(res)=>{
      open.next(false)

    }).catch(e=>{console.log(e)
      open.next(false)})
  }
    return (
      <IonPage>
          {customer?
        <IonContent  className="content">
            
        <img src="login.webp" className="cover-img" style={{position:"absolute",zIndex:"-10"}} />
        <div className="my-auto mt-40 rounded-lg  mx-auto p-4" style={{maxWidth:"350px",background:"#fff"}}>
        <TextField fullWidth  className="w-100" label="اسم المتجر"
        value={customer.name}
        onChange={(e)=>{
            customer.name=e.target.value
           setCustomer(customer)}}
         variant="standard" />
        <TextField fullWidth  className="w-100" label="اسمك"
         value={customer.person}
         onChange={(e)=>{
            customer.person=e.target.value
           setCustomer(customer)}}
         variant="standard" />

        <TextField fullWidth  className="w-100" 
         value={customer.mobile} type="number"
         onChange={(e)=>{
            customer.mobile=e.target.value
           setCustomer(customer)}}
        label="رقم الهاتف" variant="standard" />
        <TextField fullWidth  className="w-100" 
         value={customer.mobile1}
         onChange={(e)=>{
            customer.mobile1=e.target.value
           setCustomer(customer)}}
        label="رقم هاتف اخر " variant="standard" />
        <TextField fullWidth 
         value={customer.address}
         onChange={(e)=>{
            customer.address=e.target.value
           setCustomer(customer)}}
         className="w-100" label="العنوان" variant="standard" />
         <label>المنطقة</label>
        <Select placeholder="المنطقة" options={areas}
        selectedValue={customer.area_id}
        onChange={(e)=>{
            customer.area_id=e.value
           setCustomer(customer)}} />
        <br />

      
        <TextField fullWidth  className="w-100" 
         value={customer.bank}
         onChange={(e)=>{
            customer.bank=e.target.value
           setCustomer(customer)}}
        label="البنك" variant="standard" />
        <TextField fullWidth  className="w-100" 
         value={customer.account}
         onChange={(e)=>{
            customer.account=e.target.value
           setCustomer(customer)}}
        label="رقم الحساب" variant="standard" />
        
        <br />
        

        <IonButton color="light" className="w-100" onClick={()=>logout()}>
         خروج
        </IonButton>
        
        {/* <Button variant="contained" color="success"
        onClick={()=>save()}
       
         style={{marginTop:"1rem",width:"100%",borderRadius:".5rem"}} >حفظ</Button> */}
      
        </div>
        </IonContent>
        :""}
      </IonPage>
    );
  };
  
  export default Customer;