import { IonBackButton, IonButton, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import '../pages/Login.css'
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { chevronBack, chevronForward } from 'ionicons/icons'
import { withRouter } from 'react-router';
import { useEffect, useState } from "react";
import axios from "axios";
import { api, myorder, open, url } from "../store";
import Select from 'react-select'
import { Storage } from "@capacitor/storage";
const OrderUpdate = (props) => {
  const [order,setOrder]=useState(null)
  const [areas,setAreas]=useState([])
  const [present, dismiss] = useIonToast();
  useEffect(()=>{
      myorder.subscribe(res=>setOrder(res))
      
    axios.get(url+'api/areas').then(res=>{
      var list=[]
      res.data.map((item)=>{
        list.push({label:item.name,value:item.id})
      })
      setAreas(list)
    }).catch(e=>console.log(e))
  },[])

  const save=async()=>{
      var token=await Storage.get({key:"token"})
    open.next(true)
    axios.put(api+'orders/'+order.id,order)
    .then(async(res)=>{
      open.next(false)
      console.log(res.data)
        
        props.history.goBack()
      

    }).catch(e=>{console.log(e)
      open.next(false)})
  }
    return (
      <IonPage>
          <IonHeader>
              <IonToolbar>
                  <IonBackButton slot="end"></IonBackButton>
                  <IonTitle> طلب</IonTitle>
              </IonToolbar>
          </IonHeader>
          {order?
        <IonContent  className="content">
           
            <img src="login.webp" className="cover-img" style={{position:"absolute",zIndex:"-10"}} />
        <div className="my-auto mt-40 rounded-lg  mx-auto p-4" style={{maxWidth:"350px",background:"#fff"}}>
        <TextField fullWidth  className="w-100" label="رقم الطلب"
        value={order.code}
        onChange={(e)=>{
            order.code=e.target.value
            setOrder(order)}}
         variant="standard" />
        <TextField fullWidth type="number"  className="w-100" label="قيمة الطلب"
         value={order.amount}
         onChange={(e)=>{
            order.amount=e.target.value
            setOrder(order)}}
         variant="standard" />

        <TextField fullWidth  className="w-100" 
         value={order.mobile} type="number"
         onChange={(e)=>{
            order.mobile=e.target.value
            setOrder(order)}}
        label="رقم الهاتف" variant="standard" />
        <TextField fullWidth  className="w-100" 
         value={order.mobile1}
         onChange={(e)=>{
            order.mobile1=e.target.value
            setOrder(order)}}
        label="رقم هاتف اخر " variant="standard" />
        <TextField fullWidth 
         value={order.address}
        
         onChange={(e)=>{
            order.address=e.target.value
            setOrder(order)}}
         className="w-100" label="العنوان" variant="standard" />
         <label>المنطقة</label>
        <p>{order.areas?.name} </p>
        <br />
        

        <TextField fullWidth 
         value={order.note}
         onChange={(e)=>{
            order.note=e.target.value
            setOrder(order)}}
         className="my-6" label="ملاحظة" variant="standard" />
      
        
        <br />
        
        
        {/* <Button variant="contained" color="success"
        disabled={order.status_id!=6}
        onClick={()=>save()}
       
         style={{marginTop:"1rem",width:"100%",borderRadius:".5rem"}} >حفظ</Button> */}
      
        </div>
        </IonContent>
        :""}
      </IonPage>
    );
  };
  
  export default withRouter(OrderUpdate);