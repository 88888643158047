import { IonButton, IonContent, IonPage, useIonToast } from "@ionic/react";
import './Login.css'
import TextField from '@mui/material/TextField';
import { Button, InputAdornment,IconButton } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link } from "react-router-dom";
import React, { Component }  from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { withRouter } from 'react-router';
import { useEffect, useState } from 'react';
import { api, open,user } from "../store";
import Select from 'react-select'
import axio from 'axios'
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier,signInWithPhoneNumber  } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyDRO2q4RNQ7yqu-9GfFgB1fb0DGbaGSXSc",

  authDomain: "luzern-4681b.firebaseapp.com",

  databaseURL: "https://luzern-4681b.firebaseio.com",

  projectId: "luzern-4681b",

  storageBucket: "luzern-4681b.appspot.com",

  messagingSenderId: "198428012035",

  appId: "1:198428012035:web:df47ccad6e81fa56f92836",

  measurementId: "G-ERPVK45K7X"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);


const fauth = getAuth();



   const Login=(props)=> {
    const [code,setCode]=useState('249')
    const [send,setSend]=useState(true)
const [mobile,setMobile]=useState('')
const [countries,setCountries]=useState([])
const [password,setPassword]=useState('')
const [present, dismiss] = useIonToast();
const [type,setType]=useState('password')



useEffect(()=>{
  
  
  window.recaptchaVerifier = new RecaptchaVerifier('cap', {
    'size': 'invisible',
    'callback': (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      console.log(response)
    }
  }, fauth);
  axio.get('/countries.json').then(data=>setCountries(data.data)).catch(e=>console.log(e))
},[])



const auth=async()=>
{
  open.next(true)
  axio.post(api+'auth/login',{mobile:mobile,password:password}).then(async(res)=>{
    open.next(false)
    console.log(res.data)
    if(res.data.result)
    {
    localStorage.setItem('token',res.data.token)
   
      setTimeout(()=>{
        props.history.replace('/home')
      },0)
      
    }
    else{
      present({
        message: 'خطا في رقم الهاتف او كلمة المرور',
        onDidDismiss: () => console.log('dismissed'),
        onWillDismiss: () => console.log('will dismiss'),
        position:"top",
        color:"danger",
        duration:3000
      })
    }

  })
  .catch(e=>{
    open.next(false)

    console.log(e)
  })
}
 
    return (
      <IonPage>
        
        <IonContent fullscreen className="content">

            <img src="login.webp" className="cover-img " style={{zIndex:-1,position:"absolute"}} />
            <div className="flex " style={{width:"100%",height:"100vh"}}>
            <div className="container m-auto p-4" >
            <Select placeholder="الدولة"  defaultValue={{ label: "Sudan", value: 249 }}
            className={send?'d-block':'d-none '}
             options={countries} onChange={(e)=>{
               console.log(e.value,code)
               setCode(e.value)}} />
        <TextField fullWidth  className="w-100 my-2"
        type="number"
        style={{display:send?'block':'none'}}
        value={mobile}
        onChange={(e)=>{
          if(e.target.value!=0)
          {
          setMobile(e.target.value)
          }
        }}
         label="رقم الهاتف من غير 0" variant="standard" />

        <TextField fullWidth  className="mb-2"
         style={{display:!send?'block':'none'}}
         value={password}

         type='number'
         
         InputProps={{
           startAdornment:<IconButton onClick={()=>setSend(true)}>
             <ArrowForwardIcon></ArrowForwardIcon>
           </IconButton>,
          endAdornment: <InputAdornment position="start">
            <IconButton color="success"
          
                  aria-label="toggle password visibility"
                  onClick={()=>{
                    window.confirmationResult.confirm(password).then((result) => {
                      console.log(result)
                     
                      user.next(result.user)
                      props.history.push('register')
                      // ...
                    }).catch((error) => {
                      present({
                        message: 'الرجاء التاكد من الرمز المدخل',
                        onDidDismiss: () => console.log('dismissed'),
                        onWillDismiss: () => console.log('will dismiss'),
                        position:"top",
                        color:"danger",
                        duration:3000
                      })
                      // User couldn't sign in (bad verification code?)
                      // ...
                    });
                  }}
                 
                  edge="end"
                >
                  <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                </IconButton>
          </InputAdornment>,
        }}
         onChange={(e)=>setPassword(e.target.value)}
         label=" رمز التاكيد" variant="standard" />
        <br />
        <Button variant="contained" 
        
        id="sign-in-button"
        onClick={()=>{
          setSend(false)
          signInWithPhoneNumber(fauth, '+'+code+mobile, window.recaptchaVerifier)
    .then((confirmationResult) => {
      console.log(confirmationResult)
      
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      // ...
    }).catch((error) => {
     
      // Error; SMS not sent
      // ...
    });
        }}
        style={{marginTop:"1rem",width:"100%",borderRadius:".5rem",display:send?'block':'none'}} >تسجيل الدخول</Button>
      { /*
        <Link to="/register">
        <Button variant="contained" color="success" style={{marginTop:"1rem",width:"100%",borderRadius:".5rem"}} >حساب جديد</Button>
        </Link>
      */}

      

        </div>
            </div>

            <div id="cap"></div>
        
        </IonContent>
      </IonPage>
    );
       
   

    
  }
  
  export default  withRouter(Login);