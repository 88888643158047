import {BehaviorSubject} from "rxjs"

const open=new BehaviorSubject(false)
const user=new BehaviorSubject(null)

const myorder=new BehaviorSubject(null)
const mycustomer=new BehaviorSubject(null)
const url="https://backend.jai3leak.sd/"
const api=url+'api/customer/'


export{open,url,api,myorder,mycustomer,user}