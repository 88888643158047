import { IonButton, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import './Login.css'
import TextField from '@mui/material/TextField';
import { Button, IconButton, InputAdornment } from "@mui/material";
import { Link } from "react-router-dom";
import { chevronBack, chevronForward } from 'ionicons/icons'
import { withRouter } from 'react-router';
import { useEffect, useState } from "react";
import axios from "axios";
import { api, open, url, user } from "../store";
import Select from 'react-select'
import { Storage } from "@capacitor/storage";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
const Register= (props) => {
  const [name,setName]=useState('')
  const [mobile,setMobile]=useState('')
  const [password,setPass]=useState('')
  const [address,setAddress]=useState('')
  const [mobile1,setMobile1]=useState('')
  const [bank,setBank]=useState('')
  const [account,setAccount]=useState('')
  const [person,setPerson]=useState('')
  const [area_id,setArea]=useState('')
  const [areas,setAreas]=useState([])
  const [present, dismiss] = useIonToast();
  const [type,setType]=useState('password')
  const [token,setToken]=useState('')
  useEffect(()=>{
    axios.get(url+'api/areas').then(res=>{
      var list=[]
      res.data.map((item)=>{
        list.push({label:item.name,value:item.id})
      })
      setAreas(list)
    }).catch(e=>console.log(e))
    user.subscribe(data=>{
      axios.get(api+'auth/profile/'+data.uid).then(async (res)=>{
        console.log(res.data)
        setMobile(data.phoneNumber)
        setToken(data.uid)
        if(res.data.customer)
        {
          
         
          setAccount(res.data.customer.account)
          setAddress(res.data.customer.address)
          setArea(res.data.customer.area_id)
          setBank(res.data.customer.bank)
          
          setMobile1(res.data.customer.mobile1)
          setName(res.data.customer.name)
          setPerson(res.data.customer.person)
         
          
        }
        
      }).catch(e=>{
  
      })
    })
    
  },[])

  const save=async()=>{
    open.next(true)
    axios.post(api+'auth/register',{
      name:name,person:person,mobile:mobile,passowrd:password,address:address,mobile1:mobile1,
      bank:bank,account:account,area_id,token:token
    })
    .then(async(res)=>{
      open.next(false)
      console.log(res.data)
      if(res.data.result)
      {
        await localStorage.setItem('token',token)
        props.history.replace('/home')
      }
      else 
      {
        present({
          message: res.data.message,
          onDidDismiss: () => console.log('dismissed'),
          onWillDismiss: () => console.log('will dismiss'),
          position:"top",
          color:"danger",
          duration:3000
        })
      }

    }).catch(e=>{console.log(e)
      open.next(false)})
  }
    return (
      <IonPage>
        <IonContent  className="content">
            <Link to="/login">
            <IonFab>
                <IonFabButton color="light" size="small">
            <IonIcon icon={chevronForward}></IonIcon>
                </IonFabButton>
            </IonFab>
            </Link>
            <img src="login.webp" className="cover-img" style={{position:"absolute",zIndex:"-10"}} />
        <div className="my-auto mt-40 rounded-lg  mx-auto p-4" style={{maxWidth:"350px",background:"#fff"}}>
        <TextField fullWidth  className="w-100" label="اسم المتجر"
        value={name}
        onChange={(e)=>setName(e.target.value)}
         variant="standard" />
        <TextField fullWidth  className="w-100" label="اسمك"
         value={person}
         onChange={(e)=>setPerson(e.target.value)}
         variant="standard" />

       
        <TextField fullWidth  className="w-100" 
         value={mobile1}
         onChange={(e)=>setMobile1(e.target.value)}
        label="رقم هاتف اخر " variant="standard" />
        <TextField fullWidth 
         value={address}
         onChange={(e)=>setAddress(e.target.value)}
         className="w-100" label="العنوان" variant="standard" />
         <label>المنطقة</label>
        <Select placeholder="المنطقة"
        selectedValue={area_id}
         options={areas} onChange={(e)=>setArea(e.value)} />
        <br />

        
        <TextField fullWidth  className="w-100" 
         value={bank}

         onChange={(e)=>setBank(e.target.value)}
        label="البنك" variant="standard" />
        <TextField fullWidth  className="w-100" 
         value={account}
         onChange={(e)=>setAccount(e.target.value)}
        label="رقم الحساب" variant="standard" />
        
        <br />
        
        
        <Button variant="contained" color="success"
        onClick={()=>save()}
        disabled={!name||!mobile||!person||!address||!area_id}
         style={{marginTop:"1rem",width:"100%",borderRadius:".5rem"}} >تسجيل</Button>
      
        </div>
        </IonContent>
      </IonPage>
    );
  };
  
  export default withRouter(Register);